import React, { useRef } from "react";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { OPEN_CONTACT_MODAL } from "../consts";
import Popup from "reactjs-popup";
import { X } from "react-feather";
import Button from "./atoms/Button";
import ContactForm from "../components/estimate-form";

const FeaturedColumns = (data) => {
  const dataColumnsCount = data.data.map((item, index) => (
    <FeaturedColumn key={index} item={item} columnsCount={data.data.length} />
  ));
  return dataColumnsCount;
};

const FeaturedColumn = (data) => {
  const learnMoreRef = useRef(null);
  const buttonProps = {};
  const hasContactModalClass =
    data.item.classNames &&
    data.item.classNames.length > 0 &&
    data.item.classNames.filter((className) => className == OPEN_CONTACT_MODAL).length > 0;
  if (hasContactModalClass) {
    buttonProps.ref = learnMoreRef;
    if (learnMoreRef.current) {
      // learnMoreRef.current.click = e => e.preventDefault()
    }
  }

  return (
    <div key={data.item.slug} className="flex flex-col bg-white rounded-2xl  shadow-md lg:shadow-sm">
      <GatsbyImage
        className="rounded-t-2xl"
        image={getImage(data.item.heroImage)}
        style={{ width: "100%", height: "200px", objectFit: "cover" }}
        alt={data.item.serviceTitle + " Contractor"}
      />
      <div className="flex-1 relative pt-8 px-6 pb-6 md:px-8">
        <h3 className="text-xl font-bold text-gray-900">
          {data.item.featuredLabel ? data.item.featuredLabel : data.item.serviceTitle}
        </h3>
        <p className="mt-4 text-base text-gray-500">{data.item && data.item.shortExcerpt}</p>
      </div>
      <div className="px-6 pb-6 rounded-bl-2xl rounded-br-2xl md:px-8">
        {hasContactModalClass ? (
          <Popup
            trigger={
              <Button
                {...buttonProps}
                href={"/" + data.item.slug}
                color={"primary"}
                title={"Learn more about " + data.item.serviceTitle}
              >
                Learn More
              </Button>
            }
            modal
            closeOnDocumentClick
          >
            {(close) => (
              <div className="p-2 block">
                <button
                  style={{ borderWidth: "0", float: "right" }}
                  className="cursor-pointer d-block mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                  onClick={() => {
                    close();
                  }}
                >
                  <X color={"#333"} strokeWidth={"3"} className="d-block" />
                </button>
                <div className="p-3 py-4 block">
                  <h2 className="font-display font-bold text-xl" style={{ lineHeight: "1.05" }}>
                    Contact Us
                  </h2>
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.5",
                      fontSize: ".9em",
                    }}
                    className="mb-3 popupdesc"
                  >
                    If you would like to schedule a complimentary in-home design consultation and estimate or need more
                    information on a product or service that we offer, please fill out the information below.
                  </p>
                  <ContactForm
                    showServicesList={true}
                    showLabels={true}
                    showPlaceHolders={true}
                    pageLocation={
                      (data.pageProps && data.pageProps.location && data.pageProps.location.href) || "Not provided"
                    }
                  />
                </div>
              </div>
            )}
          </Popup>
        ) : (
          <Button to={data.item.slug} title={"Learn more about " + data.item.serviceTitle}>
            Learn More
          </Button>
        )}
      </div>
    </div>
  );
};

const FeaturedItems = ({ data }) => {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-gray-100">
      <section className="max-w-7xl mx-auto relative py-12 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
       
        <div className="py-6">
        {/* <h2 class="text-xl md:text-2xl font-medium font-display text-brand-600">A+ Window Tinting in Sacramento, SC
</h2> */}
</div>
        <div className="grid grid-cols-1 gap-y-4 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8 -mt-40">
          <FeaturedColumns data={data} />
        </div>
      </section>
    </div>
  );
};
export default FeaturedItems;
