import React from "react";
import google from "../img/logos/google.png";
import yelp from "../img/logos/yelp.png";
import fb from "../img/logos/fb.png";
import angies from "../img/logos/angies.png";
import bbb from "../img/logos/bbb.png";
import houzz from "../img/logos/houzz.png";
import guildquality from "../img/logos/guildquality.png";
import { Airplay, Award, Shield, Sun, Sunset } from "lucide-react";

const whyTint = [
  
  {
    "title": "Beat the Heat",
    "description": "Our window tints are engineered to significantly reduce your car's interior temperature, by at least 98%, providing you with a cooler, more comfortable environment.",
    "icon": Sun
  },
  {
    "title": "Interior Preservation",
    "description": "Safeguard your car's interior with our tints, designed to prevent fading and cracking, thus extending the life and beauty of your vehicle's interior furnishings.",
    "icon": Shield
  },
  {
    "title": "Glare Minimization",
    "description": "Enhance your driving experience by reducing glare from the sun and surrounding vehicles, ensuring a safer and more enjoyable journey.",
    "icon": Sunset
  },
  {
    "title": "Guaranteed Satisfaction",
    "description": "With A+ Window Tinting, experience the pinnacle of tint quality and enjoy the confidence of a lifetime warranty on our products.",
    "icon": Award
  }
]


const WhyTint = () => {
  return (
    <section className="bg-gradient-to-b from-[#111] to-[#333] relative shadow-sm py-12 md:py-16 font-display px-6 md:px-8">
        <h3 className="text-center text-white font-display text-2xl md:text-3xl mx-auto mb-4">Why Consider Roseville Window Tinting?</h3>
      <p className="font-normal text-center text-gray-300 font-display text-lg md:text-xl mx-auto mb-4 max-w-3xl">Offering an impressive 99% UV blocking capability, our window tints ensure your utmost protection from the sun's harmful rays, independent of the tint darkness.
</p>
      <div className={`max-w-7xl flex flex-wrap py-3 mx-auto`}>
    

        <div className={`w-full flex items-center  `}>
        
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5 text-center w-full">
            {whyTint.map((item, i) => (
              <div className="text-center backdrop-lg bg-black/40 shadow-sm rounded-xl p-6">
                <item.icon size="40" className="mb-0 mx-auto text-white" />
                <div>
                  <span className="block text-white font-semibold text-base mt-2 md:text-lg font-display">{item.title}</span>
                  <span className="block text-gray-200 text-sm mt-2 font-display font-normal">{item.description}</span>
                </div>
              </div>
            ))} 
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyTint;
